@mixin degree-counter-flash {
  -webkit-animation: flash ease-in-out 250ms;
  -moz-animation: flash ease-in-out 250ms;
  animation: flash ease-in-out 250ms;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 250ms;
  -moz-animation-duration: 250ms;
  animation-duration: 250ms;
}

@keyframes flash {
  0% {
    background: $light-grey;
  }

  50% {
    background: lighten($light-grey, 2%);
  }

  100% {
    background: $light-grey;
  }
}

@keyframes shake {
  0% {
    transform: translateX(-3px);
  }
  20% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(3px);
  }
  80% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0px);
  }
}

@mixin slide-in-from-left {
  -webkit-animation: slideInFromLeft ease-in 100ms;
  -moz-animation: slideInFromLeft ease-in 100ms;
  animation: slideInFromLeft ease-in 100ms;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 100ms;
  -moz-animation-duration: 100ms;
  animation-duration: 100ms;
}

@keyframes slideInFromLeft {
  from {
    left: -450px;
  }

  to {
    left: 0px;
  }
}

@-webkit-keyframes slideInFromLeft {
  from {
    left: -450px;
  }

  to {
    left: 0px;
  }
}

@-moz-keyframes slideInFromLeft {
  from {
    left: -450px;
  }

  to {
    left: 0px;
  }
}

@mixin slide-up-from-bottom {
  -webkit-animation: slideUpFromBottom ease-in 100ms;
  -moz-animation: slideUpFromBottom ease-in 100ms;
  animation: slideUpFromBottom ease-in 100ms;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 100ms;
  -moz-animation-duration: 100ms;
  animation-duration: 100ms;
  height: auto;
}

@keyframes slideUpFromBottom {
  from {
    bottom: -210px;
  }

  to {
    bottom: -2px;
  }
}

@-webkit-keyframes slideUpFromBottom {
  from {
    bottom: -210px;
  }

  to {
    bottom: -2px;
  }
}

@-moz-keyframes slideUpFromBottom {
  from {
    bottom: -210px;
  }

  to {
    bottom: -2px;
  }
}

@mixin grow-from-right($height) {
  -webkit-animation: growFromRight ease-in 100ms;
  -moz-animation: growFromRight ease-in 100ms;
  animation: growFromRight ease-in 100ms;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 100ms;
  -moz-animation-duration: 100ms;
  animation-duration: 100ms;
  height: $height;
  max-height: $height;
}

@keyframes growFromRight {
  from {
    width: 0px;
    height: 0px;
  }

  to {
    width: 275px;
    height: auto;
  }
}

@-webkit-keyframes growFromRight {
  from {
    width: 0px;
    height: 0px;
  }

  to {
    width: 275px;
    height: auto;
  }
}

@-moz-keyframes growFromRight {
  from {
    width: 0px;
    height: 0px;
  }

  to {
    width: 275px;
    height: auto;
  }
}

@mixin transition($duration) {
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -ms-transition: all $duration ease;
  transition: all $duration ease;
}

@mixin center-x {
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-y {
  top: 50%;
  transform: translateY(-50%);
}

@mixin zero-dimensions() {
  width: 0px;
  height: 0px;
}

@mixin max-dimensions {
  width: 100%;
  height: 100%;
}

@mixin zero-pad {
  padding: 0;
  margin: 0;
}

@mixin ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin arrow-up($color, $size: 10px) {
  @include zero-dimensions();
  content: '';
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid $color;
}

@mixin arrow-down($color, $size: 10px) {
  @include zero-dimensions();
  content: '';
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid $color;
}

@mixin arrow-right($color, $size: 10px) {
  @include zero-dimensions();
  content: '';
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;
  border-left: $size solid $color;
}

@mixin arrow-left($color, $size: 10px) {
  @include zero-dimensions();
  content: '';
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;
  border-right: $size solid $color;
}

@-webkit-keyframes fadeInFadeOut {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeInFadeOut {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInFadeOut {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@mixin fade-in-fade-out ($duration: 5s) {
  -webkit-animation: fadeInFadeOut ease-in 1;
  -moz-animation: fadeInFadeOut ease-in 1;
  animation: fadeInFadeOut ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  animation-duration: $duration;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin fade-in {
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.25s;
  -moz-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@mixin fade-out ($duration: 5s) {
  -webkit-animation: fadeOut ease-in 1;
  -moz-animation: fadeOut ease-in 1;
  animation: fadeOut ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  animation-duration: $duration;
}

@mixin box-shadow($opacity) {
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, $opacity);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, $opacity);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, $opacity);
}

@mixin text-shadow($opacity) {
  text-shadow: 0 1px 5px rgba(0, 0, 0, $opacity);
}

@mixin text-glow($opacity){
  text-shadow: 0 1px 5px rgba(255, 255, 255, $opacity);
}

@mixin animate-height($keyframe-name, $duration, $height) {
  display: block;
  -webkit-animation: #{$keyframe-name} ease-in #{$duration}s;
  -moz-animation: #{$keyframe-name} ease-in #{$duration}s;
  animation: #{$keyframe-name} ease-in #{$duration}s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: #{$duration}s;
  -moz-animation-duration: #{$duration}s;
  animation-duration: #{$duration}s;
  height: 0;
  overflow: hidden;

  @-webkit-keyframes #{$keyframe-name} {
    from {
      height: 0;
    }
    to {
      height: $height;
    }
  }

  @-moz-keyframes #{$keyframe-name} {
    from {
      height: 0;
    }
    to {
      height: $height;
    }
  }

  @keyframes #{$keyframe-name} {
    from {
      height: 0;
    }
    to {
      height: $height;
    }
  }
}

@mixin createTourInputs {
  width: 100%;
  padding: 2px 2px 2px 0;
  border: 0;
  border-radius: 3px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  transition: all 0.25s ease;
  background: transparent;
  border-bottom: 1px solid $blue;
  box-shadow: 0 1px 0 0 transparent;
  border-radius: 0px;

  &:read-only {
    border-bottom: 1px solid $medium-grey;
    box-shadow: 0 1px 0 0 transparent;
  }

  &:focus {
    outline: none;
    box-shadow: 0 1px 0 0 $blue;
  }
}

@mixin tourStopInputs {
  padding: 2px 2px 2px 0;
  color: $ultradark-grey;

  &:read-only {
    color: $superdark-grey;
  }

  &:focus {
    color: $ultradark-grey;
    outline: none;
  }

  &::placeholder {
    font-size: $rem-font-size-small-2;
    font-style: italic;
    color: $medium-grey;
  }
}

@mixin standardButtonStyle {
  color: $superlight-grey;
  border: 2px solid $superlight-grey;

  &:hover {
    background-color: $superlight-grey;
    color: $ultradark-grey;
  }
}

@mixin c3d-tos-privacy-btns {
  position: absolute;
  bottom: 8px;
  padding: 0;
  font-size: 0.8em;
  z-index: 98;
  background: none;
  border: none;
  bottom: 10px;
  color: #f1f1f1;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  &:hover {
    color: white;
    box-shadow: none;
    text-decoration: underline;
  }
}

@mixin wysiwyg-editor-font {
  &-Open_Sans {
    font-family: 'Open Sans', sans-serif;
  }
  &-Poppins {
    font-family: 'Poppins', sans-serif;
  }
  &-Monospace {
    font-family: monospace;
  }
  &-Arial {
    font-family: Arial, Helvetica, sans-serif;
  }
  &-Helvetica {
    font-family: Helvetica, Arial, sans-serif;
  }
  &-Times_New_Roman {
    font-family: 'Times New Roman', Times, serif;
  }
  &-Courier {
    font-family: 'Courier New', Courier, monospace;
  }
  &-Georgia {
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  &-Trebuchet {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
}

// prevents images from being dragged as user drags
@mixin UndraggableContent {
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

// use inside of an ::after pseudoselector to add BETA text
@mixin BetaTag () {
  content: 'BETA';
  font-weight: bold;
  color: $success;
  font-size: $rem-font-size-small-1;
  border-radius: 20px;
  border: 1px solid $success;
  margin-left: 10px;
  padding: 3px 6px;
}

// include inside of a <div>'s ::after to add 'Locked'
// developer will have to modify top, height, width, left properties based on use case (see StopMediaLanding.scss)
@mixin LockedFeature {
  content: 'Locked';
  font-style: normal;
  color: white;
  font-size: $rem-font-size-small-1;
  transform: rotate(45deg);
  position: absolute;
  // the border, height, width combination makes a trapezoid
  border-bottom: 12px solid $dark-grey;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}