$tablet-width: 768px;
$desktop-width: 1025px;
$desktop-large-width: 2049px;

@mixin desktop-large {
  @media (min-width: #{$desktop-large-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet-landscape {
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (orientation: portrait) {
    @content;
  }
}

@mixin mobile-landscape {
  @media only screen and (min-width: 414px) and (max-width: 812px) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 420px) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

@mixin ie10-up {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin firefox {
  @supports (-moz-appearance: meterbar) and (list-style-type: japanese-formal) {
    @content;
  }
}

@mixin mobile-and-tablet {
  @media screen and (max-width: 420px),
  (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin displayFlex {
  display: flex;
}

@mixin flexItemsCenterAligned {
  align-items: center;
}

@mixin flexItemsLeftAligned {
  align-items: left;
}
