@import 'styles/variables.scss';

.cache-buster-modal {
  @include box-shadow(0.15);
  &__content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}