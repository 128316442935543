@use "@react-md/theme/dist/color-palette" as *;
@forward "react-md" with (
  // I am bad at colors and chose 2 random ones from https://flatuicolors.com/palette/defo
  // probably looks terrible so wouldn't recommend copying this example
  $rmd-theme-primary: #009b65,
  $rmd-theme-on-primary: white,
  $rmd-theme-secondary: #214c6c,
  $rmd-button-text-border-radius: 2px,
  // // make buttons bigger
  // $rmd-button-text-horizontal-padding: 1.5rem,
  // $rmd-button-text-border-radius: 0.25rem,
  // $rmd-button-text-height: 3rem,
  // // add more padding to the base list
  // $rmd-list-vertical-padding: 1rem,
  // $rmd-list-dense-vertical-padding: 0.75rem,
  // // make each list item a bit more dense
  // $rmd-list-item-height: 2.5rem,
  // $rmd-list-item-dense-height: 2rem,
  // $rmd-list-item-medium-height: 3rem,
  // $rmd-list-item-dense-medium-height: 2.75rem
);

@use "react-md" as *;

@include react-md-utils;
@import "styles/globals";

body {
  margin: 0;
}

input,
button,
.rmd-button,
.rmd-switch__input {
  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
    opacity: 1;
  }
}
.rmd-switch__input {
  &:focus {
    appearance: none;
  }
}

h1,
.rmd-card__subtitle {
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
}

label {
  color: gray;
}

.rmd-toggle__input {
  &:focus {
    opacity: 1;
  }
}
.rmd-button--text {
  text-transform: none;
}

.rmd-switch {
  background: rgba(33, 76, 108, 0.5);
}
.rmd-button:disabled {
  &:hover {
    box-shadow: none;
    cursor: inherit;
    color: inherit;
  }
}

.rmd-icon--font {
  padding-right: 10px;
}

.rmd-text-field {
  font-size: 0.8rem;
}
.rmd-grid {
  margin: 0 auto;
}
