@import 'styles/variables.scss';

.ReactModal__Overlay,
.ReactModal__Content {
  transition: opacity 500ms ease-in-out;
  opacity: 0;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.dialog-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;

  &.no-background {
    background: unset;
  }

  &.form-modal {
    background: rgba(0, 0, 0, 0.5);
  }

  &.tutorial-modal {
    background: rgba(0, 0, 0, 0.6);
  }

  .stop-media-gallery {
    width: 100%;

    @include desktop {
      width: auto;
      outline: inherit;
    }

    .dialog-modal-close-btn {
      top: 5px;
      right: 10px;
      font-size: $rem-font-size-huge-2;
    }

    .media-gallery-name {
      position: absolute;
      top: 6px;
      left: 10px;
      color: $light-grey;
      font-weight: 400;
      z-index: 1;
      line-height: 27px;
      font-size: $rem-font-size-medium-2;
    }

    #tabs-wrapper {
      #tab-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);

        &:after {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        @include mobile {
          width: auto !important;
          flex: 1;
          top: auto;
          bottom: 0;
          position: absolute;
          margin-bottom: 0;
        }

        @include tablet {
          width: auto !important;
          flex: 1;
          top: auto;
          bottom: 0;
          position: absolute;
          margin-bottom: 0;
        }

        .tab {
          padding: 10px 0 5px 0;

          &:hover {
            color: $light-grey;
            border-bottom: 3px solid $medium-grey;
          }

          .material-icons {
            vertical-align: -5px;
          }

          .tab-title {
            text-transform: uppercase;
          }

          &.gallery {
            @include mobile {
              width: auto !important;
              flex: 1;
            }

            @include tablet {
              width: auto !important;
              flex: 1;
            }
          }
        }
      }

      #tab-panel {
        -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
        min-height: unset;
        width: 100%;

        @include desktop {
          width: auto;
        }

        .main-media {
          width: 100%;

          @include desktop {
            width: 800px;
          }

          img,
          iframe {
            @include fade-in;
            width: 100%;
          }

          img {
            height: auto;
          }

          iframe {
            margin-bottom: -4px;

            @include mobile {
              height: 265px;
            }
          }
        }
      }
    }
  }
}

.dialog-modal {
  outline: none;

  > div {
    min-width: 90%;
    max-width: 90%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    background: $superlight-grey;
    margin: auto;
    border-radius: 4px;
    outline: none;
    overflow-y: auto;
    overflow-x: hidden;

    @include mobile {
      margin-top: 55px;
    }

    @include desktop {
      min-width: 960px;
      max-width: 1024px;
      padding: 30px 50px;
    }
  }

  .redirect-modal {
    padding: 20px;
    min-width: 550px;
  }

  &.share {
    width: 100%;
    height: 100%;

    @include desktop {
      width: auto;
      height: auto;
    }
  }

  .dialog-modal-close-btn {
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    top: 12px;
    right: 17px;
    padding: 0;
    border: none;
    color: $superlight-grey;
    font-weight: 100;
    font-size: 3rem;
    z-index: 1;
    line-height: 26px;
    font-weight: 100;

    &:hover {
      color: white;
      box-shadow: none;
    }
  }

  #forms-container {
    padding: 0px 10px;

    @include mobile {
      margin-top: 0;
      height: 100%;
    }
  }
}
