@import "styles/variables.scss";

button,
a {
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 20px;
  transition: all 0.25s ease;

  &:hover {
    cursor: pointer;

    @include box-shadow(0.25);
  }

  &.primary-btn {
    color: $blue;
    border: 2px solid $blue;

    &:hover {
      color: white;
      background-color: $blue;
    }

    &:disabled {
      cursor: default;
      opacity: 0.2;
      color: inherit;
      background: inherit;
      box-shadow: none;
    }
  }

  &.disabled {
    color: #cccccc;
    border: 2px solid #cccccc;
  }

  &.text-btn {
    padding: 0;
    font-size: 11px;
    border: none;
    text-decoration: none;
    color: $blue;

    &:hover {
      box-shadow: none;
      color: darken($blue, 10%);
    }

    &:disabled {
      cursor: default;
      opacity: 0.2;
    }

    &.cancel-btn {
      color: $dark-grey;

      &:hover {
        color: $superdark-grey;
      }
    }
  }

  &.back-btn {
    margin-bottom: 5px;
  }

  &.share-btn {
    &:hover {
      box-shadow: none;
    }
  }

  &.pano-ctrl-btn {
    padding: 0;
    outline: none;
    z-index: 1;
    color: #fff;
    border: none;
    margin: 0 5px;
    flex: 1;
    padding: 0;
    height: 100%;
    margin: 0;
    @include fade-in;
    border-radius: 0;

    #minimap-wrapper & {
      display: none;
    }

    @include desktop {
      border-radius: 50%;
      padding: 5px;
      flex: 0;
      width: 32px;
      height: 32px;
      background-color: rgba(0, 0, 0, 0.35);
      margin-right: 10px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.75);
        box-shadow: none;
      }
    }

    &.full-screen {
      transform: rotate(45deg);
    }

    &.full-screen,
    &.rotate {
      i {
        font-size: 1.5rem;
        margin: 1px 0 0 0;
      }
    }

    &.vr {
      @include desktop {
        height: 32px;
        padding: 8px;
      }
    }

    &.share {
      padding: 9px;
    }
  }
}

.material-icons {
  font-size: 1rem;
  vertical-align: -3px;

  &.prev {
    transform: rotate(180deg);
  }
}

.styles-btn {
  margin-right: 15px;
}

.delete-btn {
  color: $error;
  border-color: $error;

  &:hover {
    color: white;
    background: $error;
  }
}

.btn-update {
  height: 25px;
  width: 80px;
  color: $success;
  border-color: $success;
  outline: none;

  i {
    margin-right: 2px;
    @include fade-in;
  }

  > div {
    margin-right: 4px;
    vertical-align: -1px;
  }

  &:hover {
    background-color: $success;
    color: white;
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.75;
    pointer-events: none;
  }
}
