$rem-font-size-tiny-1: 0.3rem;
$rem-font-size-tiny-2: 0.6rem;
$rem-font-size-tiny-3: 0.7rem;
$rem-font-size-small-1: 0.75rem;
$rem-font-size-small-2: 0.8rem;
$rem-font-size-small-3: 0.85rem;
$rem-font-size-small-4: 0.9rem;
$rem-font-size-small-5: 0.95rem;
$rem-font-size-medium-1: 1rem;
$rem-font-size-medium-2: 1.1rem;
$rem-font-size-medium-3: 1.2rem;
$rem-font-size-medium-4: 1.25rem;
$rem-font-size-medium-5: 1.3rem;
$rem-font-size-medium-6: 1.35rem;
$rem-font-size-medium-7: 1.4rem;
$rem-font-size-large-1: 1.5rem;
$rem-font-size-large-2: 1.8rem;
$rem-font-size-large-3: 2rem;
$rem-font-size-huge-1: 2.625rem;
$rem-font-size-huge-2: 2.8rem;
$rem-font-size-huge-3: 4.5rem;
